{
  "name": "mt-portal",
  "version": "0.6.43",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration production",
    "build:stage": "ng build --configuration stage",
    "prebuild": "npm --no-git-tag-version version patch",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@ag-grid-community/angular": "^32.3.0",
    "@ag-grid-community/client-side-row-model": "^32.3.0",
    "@ag-grid-community/core": "^32.3.0",
    "@ag-grid-community/styles": "^32.3.0",
    "@ag-grid-enterprise/clipboard": "^32.3.0",
    "@ag-grid-enterprise/core": "^32.3.0",
    "@ag-grid-enterprise/master-detail": "^32.3.0",
    "@ag-grid-enterprise/menu": "^32.3.0",
    "@ag-grid-enterprise/range-selection": "^32.3.0",
    "@ag-grid-enterprise/row-grouping": "^32.3.0",
    "@ag-grid-enterprise/server-side-row-model": "^32.3.0",
    "@ag-grid-enterprise/set-filter": "^32.3.0",
    "@ag-grid-enterprise/status-bar": "^32.3.0",
    "@angular/animations": "^18.2.13",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "ag-charts-angular": "^10.3.0",
    "ag-charts-enterprise": "^10.3.0",
    "jwt-decode": "^4.0.0",
    "primeng": "^17.18.12",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@faker-js/faker": "^8.4.1",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^20.12.7",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "3.2.5",
    "typescript": "~5.5.4"
  }
}
